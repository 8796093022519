.terms_container{
    color: black;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.terms_container > h1{
    font-weight: 700;
    font-size: 3rem;
}

.terms_container > h6{
    font-size: 1.2rem;
    padding-top: 1rem;
}

.terms_container > div{
    font-size: 1.2rem;
    padding-top: 1rem;
}

.terms_container > p{
    font-size: 1rem;
    text-align: justify;
    padding: 4rem;
}

.part__footer{
    padding-left: .7rem;
}

.flex_row{
    display: flex;
    flex-direction: row;
}