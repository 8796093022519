.nav-link {
    font-size: 1rem !important;
}

#basic-navbar-nav{
    height: 100% !important;
}

.me-auto{
    gap: 1rem;
}

.css-1g5k5ll img{
    height: 20px !important;
    width: 20px !important;
}

.css-1g5k5ll {
    padding: 2px 12px !important;
}
.css-1d4s0we{
    gap: 1px !important;
}

.tw-connected-wallet__address{
    font-size: .8rem !important;
}

.css-7jssb5 {
    padding: 8px 10px !important;
}

.img_footer{
    width: 4.5rem;
    height: auto;
}

.h6_footer{
    color: #d8bc61;
    margin-bottom: 0 !important;
}

.p_footer{
    font-size: .9rem;
}

.container_footer{
    flex-direction: column;
}
.p_footer{
    text-align: center;
}

.nav__top{
    border-bottom: 1px solid white;
}

.nav__bottom{
    border-top: 1px solid white;
}

.terms_footer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: .8rem;
    gap: .6rem;
}

.terms_footer > p:nth-child(1), .terms_footer > p:nth-child(3) {
    color: #d8bc61;
    cursor: pointer;
}

.terms_footer > p:nth-child(1):hover, .terms_footer > p:nth-child(3):hover {
    text-decoration: underline;
}