.App {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.main_page{
  flex: 1;
}

.success_p{
  color: green;
  text-align: center;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
}

.success_p:hover{
  text-decoration: underline;
}