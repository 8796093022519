.men_p{
    color: rgba(0, 0, 0, 0.596);
    font-size: 1rem;
}

.container_dashboard{
    padding-top: 4rem;
    padding-bottom: 5rem;

    position: relative;
}

.men_h{
    color: black;
    font-size: 1.1rem;
    font-weight: 700;
}

.home_dash:hover{
    color: black;
}

.card_display{
    display: flex;
    padding-top: 3rem;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;

    gap: 2rem;
}

.card_container{
    color: black;
    padding: 0.7rem 1rem;
    border-radius: 10px;
    position: relative;
    min-height: 6rem;
    
    width: 100%;
    background: white;

    box-shadow: 4px 2px 6px 1px rgb(0 0 0 / 55%);
    -webkit-box-shadow: 4px 2px 6px 1px rgb(0 0 0 / 55%);
    -moz-box-shadow: 4px 2px 6px 1px rgb(0 0 0 / 55%);
}



.assets_card{
    color: black;
    border-radius: 10px;
    padding: 1rem 1.5rem !important;

    display: flex;
    flex-direction: column;

    background: white;
    
    box-shadow: 4px 2px 6px 1px rgb(0 0 0 / 55%);
    -webkit-box-shadow: 4px 2px 6px 1px rgb(0 0 0 / 55%);
    -moz-box-shadow: 4px 2px 6px 1px rgb(0 0 0 / 55%);
}

.icon_card_dash{
    position: absolute;
    top: -1rem;
    left: 1rem;
    padding: 1rem 1rem;
    border-radius: 10px;
    color: white;

    font-size: 1.3rem;
}

.sub_card{
    font-size: .9rem;
    color: rgba(0, 0, 0, 0.507);
    text-align: end;
    font-weight: 500;
}

.value_card{
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 1.8rem;
    text-align: end;
}

.value_asset_card{
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.8rem;
    text-align: end;
}

.quest__dash{
    font-size: .6rem;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    border-radius: 50%;
    padding: .15rem .25rem;
    cursor: pointer;
}

#tooltip-bottom{
    font-size: .7rem !important;
}

.circle_card{
    color: white;
    font-size: 1.2rem;
    padding: 1rem 1.15rem;
    background: green;
    border-radius: 50%;

    position: relative;
    z-index: 10;
}

.circle_card1{
    color: white;
    font-size: 1.2rem;
    padding: 1rem 1rem;
    background: #d8bc61;
    border-radius: 50%;
    position: relative;
    z-index: 10;
}

.value_semcard{
    font-size: 1.2rem;
    font-weight: 600;
}

.desc_semcard{
    font-size: .8rem;
    color: rgba(0, 0, 0, 0.596);
    line-height: .8rem;
}

.flexed_assets_card{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 1.5rem;

    padding-top: 2.5rem;
    padding-bottom: 3rem;
    padding-left: .5rem;

    position: relative;
    z-index: 10;
}

.bottom_arrow::after{
    content:""; 
    background: rgba(0, 0, 0, 0.596); 
    position: absolute; 
    bottom: -2rem; 
    left: 1.5rem; 
    height: 100%; 
    width: 1.5px;

    z-index: 1;
}

.p_assets_card{
    font-size: .8rem;
    font-weight: 500;
}

.no_show{
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.596);
    text-align: center;
}

.refresh_dash{
    font-size: 1.5rem;
    color: black;
    cursor: pointer;

    transition: .3s;
}

.refresh_dash:hover{
    transform: scale(1.1);
}