.home_section{
    height: 100%;
}

.display_center{
    display: flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.display_end{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}

.display_spacebetween{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.full_height{
    height: 100% !important;
}

.display_start{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    position: relative;
}

.cursor_pointer{
    cursor: pointer;
}

.banner__home{
    height: 35rem;
    background: rgb(10, 10, 10);
}

.h1__home{
    color: #d8bc61; 
    text-align: left;
}

.p__home{
    text-align: left;
    font-size: 1rem;
}

.p_how{
    text-align: left;
    font-size: 1.1rem;

    font-weight: 500;
}

.h6__home, .text_red{
    color: red;
}

.btn__banner{
    background-color: red !important;
    color: white !important;
    border: 1px solid red !important;
}

.banner__home > .container, .banner__home > .container > .row{
    height: 100%;
}

.gold_gif{
    width: 15rem;
}

.stars_home{
    color: rgb(245, 173, 67);
    font-size: 1.2rem;
}

.card_home{
    width: 14rem;
    cursor: pointer;
}

.text_center{
    text-align: center;
}

.text_black{
    color: rgb(10, 10, 10);
}

.text_header{
    font-size: 3rem;
}

.card_home_holder{
    background: #1d1c27;
    width: fit-content;
    border-radius: 10px;
    padding: 2rem 3rem;

    display: flex;
}

.text_1em{
    font-size: 1rem
}

.hr_home{
    background: rgba(255, 255, 255, 0.918);
    height: .2rem;
    opacity: 1;
}

.step_home{
    min-width: 5rem;
}

.gap_row{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.full_width{
    width: 100%;
}

.flex_end{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.text_primary{
    color: #d8bc61;
}

.cards__home{
    min-height: 30rem;
    background: url('../img/bg-ribs.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    padding-bottom: 5rem;
}

.h1_cards{
    text-align: center;
    text-shadow: rgb(255, 255, 255) 0px 0px 5px;
    font-size: 2.5rem;

    padding-top: 6rem;
    font-weight: bold;
}

.p_cards{
    text-align: center;
    font-size: .9rem;
    font-weight: bold;

    padding-bottom: 2rem;
}

.row_cards{
    gap: 2.5rem;
    align-items: center;
}

.col_cards{
    cursor: pointer;
    
    padding-top: 4rem;
    padding-bottom: 2rem;
    padding-left: 0 !important;
    padding-right: 0 !important;

    background-color: rgb(123 123 123);
    border-color: rgb(87 87 87);
    margin-top: 20px !important;
    border-width: 5px;
    border-style: solid;
    box-shadow: 0 2px 5px 2px rgba(0,0,0,0.4) inset;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    height: fit-content !important;
    width: fit-content !important;
    transition: .3s;
}

.col_cards:hover{
    transform: scale(1.1);
}

.col_cards:nth-child(2){
    background-color: rgb(39 48 49);
    border-color: rgb(174 229 210);
}

.col_cards:nth-child(3){
    background-color: rgb(93 75 39);
    border-color: rgb(232, 187, 102);
}

.col_cards > h6{
    font-size: 1.8rem;
}

.col_cards > h3{
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: rgb(220, 30, 14);
    border: 1px solid white;
    border-radius: 20px;
    padding: .5rem 1rem;

    margin-bottom: 1rem;
}

.col_cards > h4{
    font-size: 2.5rem;
    font-weight: 700;
}

.btn_cards{
    color: rgb(255, 255, 255) !important;
    background: #30a05c !important;
    box-shadow: inset 0 2px 2px 0 rgba(255,255,255,0.22), 0 233px 233px 0 rgba(255,255,255,0.12) inset !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-bottom: 3px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 3px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.col_cards > p{
    text-align: center;
    color: rgb(255, 255, 255);
    font-weight: 700;
    font-size: 1rem;
}

.img__cards{
    width: 90%;
}

.floating_bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.circle_primary{
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    background: #d8bc61;
}

.container__circle{
    filter: blur(100px);
}

.question_title{
    color: black;
    font-weight: 500;
}

.container__questions{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 1.5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 9rem;
    padding-right: 9rem;

    font-size: 1.4rem;
}

.question_answer{
    color: black;
    font-size: 1rem;
    padding-top: 1.3rem;
    padding-left: 1rem;
    padding-right: 4rem;
    text-align: justify;

    transition: .3s;
}

.display_none{
    animation: fade-out 0.3s ease-out forwards;
}

.display_block{
    animation: fade-in 0.3s ease-in forwards;
}

@keyframes fade-out {
    0% {
      opacity: 1;
      display: block;
    }
  
    100% {
      opacity: 0;
      display: none;
    }
}

@keyframes fade-in {
    0% {
      opacity: 0;
      display: none;
    }
  
    100% {
      opacity: 1;
      display: block;
    }
  }

.h6_questions{
    font-size: 2rem;
    font-weight: 600;
    padding-top: 4rem;
    padding-bottom: 2rem;
}

.btn_questions{
    background: white !important;
    border: 1px solid black !important;
    font-weight: 400 !important;
    font-size: 1.3rem !important;
    border-radius: 0px !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.btns_container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 1.2rem;

    padding-bottom: 6rem;
}

.feq_cont{
    min-height: 70rem;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}