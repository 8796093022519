/*TV/EXTRA LARGE*/
@media (min-width: 1201px){
     /*DASH*/
     .col__assets{
        width: fit-content !important;
        min-width: fit-content !important;
        max-width: fit-content !important;
        height: fit-content !important;
        max-height: fit-content !important;
        min-height: fit-content !important;
    }
}
/*DESKTOP LARGE*/
@media (min-width: 1025px) and (max-width: 1200px){
     /*DASH*/
     .col__assets{
        width: fit-content !important;
        min-width: fit-content !important;
        max-width: fit-content !important;
        height: fit-content !important;
        max-height: fit-content !important;
        min-height: fit-content !important;
    }
}
/*LAPTOPS*/
@media (min-width: 769px) and (max-width: 1024px){
    /*HOME*/
    .step_home{
        min-width: 6.5rem;
    }
    .text_header {
        font-size: 2.5rem;
    }
    .col_cards > h6 {
        font-size: 1.3rem;
    }
    .row_cards {
        gap: 1.2rem;
    }
    .col_cards > h3 {
        font-size: 1.4rem;
    }
    .col_cards > p {
        font-size: .9rem;
    }
    .container__questions {
        padding-left: 6rem;
        padding-right: 6rem;
    }

    /*DASH*/
    .card_display {
        gap: .9rem;
    }
    .icon_card_dash {
        padding: .8rem;
    }
    .value_card {
        font-size: 1.5rem;
    }
    .sub_card{
        font-size: .8rem;
    }
    .col__assets{
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
        height: fit-content !important;
        max-height: fit-content !important;
        min-height: fit-content !important;
    }
    .row__assets{
        gap: 1.3rem;
    }
}
/*IPADS/TABLETS*/
@media (min-width: 481px) and (max-width: 768px){
    /*HOME*/
    .row_home_banner{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .banner__home {
        min-height: 35rem;
        height: 100%;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .h1__home, .banner__home, .p__home{
        text-align: center;
    }
    .h6__home{
        padding-bottom: 2rem;
    }
    .btn__banner{
        margin-bottom: 2rem;
    }
    .text_header {
        font-size: 2.5rem;
    }
    .step_home {
        min-width: 6rem;
    }
    .h1_cards{
        font-size: 1.7rem;
    }
    .p_cards {
        font-size: .8rem;
    }
    .row_cards{
        justify-content: center;
    }
    .col_cards{
        width: 85% !important;
        min-width: 85% !important;
        max-width: 85% !important;
    }
    .container__questions {
        padding-left: 3rem;
        padding-right: 3rem;
        font-size: 1.2rem;
    }
    .h6_questions {
        font-size: 1.5rem;
    }
    .btn_questions {
        font-size: 1rem !important;
    }
    /*DASH*/
    .card_display {
        gap: .9rem;
    }
    .icon_card_dash {
        padding: .8rem;
    }
    .value_card {
        font-size: 1.5rem;
    }
    .sub_card{
        font-size: .8rem;
    }
    .col__assets{
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
        height: fit-content !important;
        max-height: fit-content !important;
        min-height: fit-content !important;
    }
    .row__assets{
        gap: 1.3rem;
    }
    .card_display{
        flex-direction: column;
        gap: 2rem;
    }
    .no_show {
        min-height: 15rem;
    }
}
/*MOBILE*/
@media (max-width: 480px){
        /*HOME*/
        .row_home_banner{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
        }
        .banner__home {
            min-height: 35rem;
            height: 100%;
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
        .h1__home, .banner__home, .p__home{
            text-align: center;
        }
        .h6__home{
            padding-bottom: 2rem;
        }
        .btn__banner{
            margin-bottom: 2rem;
        }
        .text_header {
            font-size: 2rem;
        }
        .p_how {
            font-size: 1rem;
            padding-top: 1rem;
        }
        .step_home {
            min-width: 6rem;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            color: #d8bc61;
        }
        .h1_cards{
            font-size: 1.5rem;
        }
        .p_cards {
            font-size: .7rem;
        }
        .row_cards{
            justify-content: center;
        }
        .col_cards{
            width: 85% !important;
            min-width: 85% !important;
            max-width: 85% !important;
        }
        .container__questions {
            padding-left: 1rem;
            padding-right: 1rem;
            font-size: 1.2rem;
        }
        .question_answer{
            padding-right: 2rem;
        }
        .question_title{
            padding-right: 1rem;
        }
        .h6_questions {
            font-size: 1.5rem;
        }
        .btn_questions {
            font-size: 1rem !important;
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
        .card_home_holder{
            flex-direction: column;
        }
        .hide_br{
            display: none;
        }
        /*DASH*/
        .card_display {
            gap: .9rem;
        }
        .icon_card_dash {
            padding: .8rem;
        }
        .value_card {
            font-size: 1.5rem;
        }
        .sub_card{
            font-size: .8rem;
        }
        .col__assets{
            width: 100% !important;
            min-width: 100% !important;
            max-width: 100% !important;
            height: fit-content !important;
            max-height: fit-content !important;
            min-height: fit-content !important;
        }
        .row__assets{
            gap: 1.3rem;
        }
        .card_display{
            flex-direction: column;
            gap: 2rem;
        }
        .no_show {
            min-height: 15rem;
        }
        .ps-5{
            padding-left: 0 !important;
        }
        .App {
            overflow: hidden;
        }
}

@media (max-width: 300px) {
    .col_cards > h6 {
        font-size: 1.2rem;
    }
    .btns_container {
        flex-direction: column;
    }
}